import React from "react";
import { InfoIcon } from "./Info";
import { formatNtee, stringifyInteger } from "../utils";
import { Table } from "./Table";

// Structured this way because React prefers composition over
// inheritance
// https://reactjs.org/docs/composition-vs-inheritance.html
function TablePane({ title, titleInfoContent, subtitle, table }) {
  // Unfortunately, we can't programmatically set the
  // element height (and have it apply to both the
  // `section` and the table `div`) because the
  // Tailwind CSS preprocessor runs _before_ the React
  // classes are resolved
  return (
    <section className={"border border-black w-1/2 h-[40rem]"}>
      <div className="ml-6 mt-4">
        <h2 className="text-2xl">
          {title} <InfoIcon elementName={title} content={titleInfoContent} />
        </h2>
        <h3 className="text-lg mt-1">{subtitle}</h3>
      </div>
      {/*
        This `height` calculation is definitely a hack, and
        may need manual tweaking as `h2` style or padding
        changes in the future for this project. Miles tried
        to make `height` work using CSS Flexbox, which should
        be the proper method for calculating "container height
        remaining," but Flexbox didn't seem to work well with
        RSuite Table. Specifically when using Flexbox with
        RSuite Table's `fillHeight` property, the table would
        start at the correct height, but then immediately
        re-render and increase its height (thus overflowing the
        container), and then re-render and increase height again
        and again indefinitely.

        http://rsuite.github.io/rsuite-table/#3
        https://rsuitejs.com/components/table/#appearance
      */}
      <div className={"h-[calc(40rem-6rem)]"}>{table}</div>
    </section>
  );
}

class NonprofitTablePane extends React.PureComponent {
  columnConfig = [
    {
      cellType: "name",
      header: "Name",
      value: (row) => row.name,
      width: 280,
      sortable: true,
      fixed: true,
    },
    {
      cellType: "text",
      header: "ZIP Code",
      value: (row) => row.zip5,
      width: 100,
      sortable: true,
    },
    {
      cellType: "text",
      header: "Street Address",
      value: (row) => row.street,
      width: 200,
      sortable: true,
    },
    {
      cellType: "linkIcon",
      header: "Website",
      value: (row) => (row.website ? row.website : null),
      width: 90,
      sortable: true,
    },
    {
      cellType: "text",
      header: "NTEE Description",
      value: (row) => formatNtee(row.ntee, row.description3),
      width: 160,
      sortable: true,
      headerLink:
        "https://nccs.urban.org/project/national-taxonomy-exempt-entities-ntee-codes",
    },
    {
      cellType: "text",
      header: "Tax Year",
      value: (row) => this.props.taxYear,
      width: 110,
      sortable: true,
    },
    {
      cellType: "text",
      header: "Return Type",
      value: (row) => row.return_type,
      width: 120,
      sortable: true,
    },
    {
      cellType: "number",
      header: "Revenue",
      value: (row) =>
        row.revenue_timeline && row.revenue_timeline[this.props.taxYear],
      width: 120,
      sortable: true,
    },
    {
      cellType: "number",
      header: "Count Employees",
      value: (row) =>
        row.employee_timeline && row.employee_timeline[this.props.taxYear],
      width: 160,
      sortable: true,
    },
    {
      cellType: "number",
      header: "Count Volunteers",
      value: (row) =>
        row.volunteer_timeline && row.volunteer_timeline[this.props.taxYear],
      width: 160,
      sortable: true,
    },
    {
      cellType: "text",
      header: "Topics",
      // In order for null-always-to-bottom sorting to work, must
      // set value to `null` if there are no issue areas
      value: (row) => row.org_classes.join(", ") || null,
      width: 120,
      sortable: true,
    },
    {
      cellType: "number",
      header: "Community Nonprofits Funded (#)",
      value: (row) => row.grantsForSelectedYear.length,
      width: 280,
      sortable: true,
    },
  ];

  render() {
    const nonprofitFundersCount = this.props.data.filter(
      (i) => i.grantsForSelectedYear.length
    ).length;
    const nonprofitsCount = this.props.data.length - nonprofitFundersCount;
    const subtitle = `${stringifyInteger(
      nonprofitsCount
    )} Nonprofits and ${stringifyInteger(
      nonprofitFundersCount
    )} Nonprofit Funders`;

    return (
      <TablePane
        title="Community Nonprofits"
        titleInfoContent={
          <React.Fragment>
            <p className="mb-4">
              Displays a list of nonprofits and nonprofit funders,{" "}
              <a
                className="underline
              text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://www.irs.gov/charities-non-profits/form-990-series-which-forms-do-exempt-organizations-file-filing-phase-in"
                target="_blank"
                rel="noreferrer"
              >
                tax-exempt organizations
              </a>{" "}
              that file Form 990 and 990EZ, in the selected geography. Nonprofit
              Funders are defined as{" "}
              <a
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://www.irs.gov/charities-non-profits/form-990-series-which-forms-do-exempt-organizations-file-filing-phase-in"
                target="_blank"
                rel="noreferrer"
              >
                tax-exempt organizations
              </a>{" "}
              in the selected geography that have made grants to other
              tax-exempt organizations in the selected geography.
            </p>
            <p>
              NTEE Description: The National Taxonomy of Exempt Entities (NTEE)
              system was developed by the National Center for Charitable
              Statistics (NCCS) and is used by the Internal Revenue Service
              (IRS) and NCCS to classify tax-exempt organizations. The IRS
              assigns an NTEE code to each tax-exempt organization. See NCCS'{" "}
              <a
                className="underline
              text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://nccs.urban.org/project/national-taxonomy-exempt-entities-ntee-codes"
                target="_blank"
                rel="noreferrer"
              >
                documentation
              </a>{" "}
              for a more detailed definition.
            </p>
          </React.Fragment>
        }
        subtitle={subtitle}
        table={
          <Table
            tableClasses="mt-4"
            columnConfig={this.columnConfig}
            data={this.props.data}
            taxYear={this.props.taxYear}
            onRowClick={this.props.setModalEin}
            rsuiteTableProps={{ fillHeight: true, virtualized: true }}
          />
        }
      />
    );
  }
}

class FunderTablePane extends React.PureComponent {
  columnConfig = [
    {
      cellType: "name",
      header: "Name",
      value: (row) => row.funder_name,
      width: 280,
      sortable: true,
      fixed: true,
    },
    {
      cellType: "text",
      header: "City",
      value: (row) => row.funder_city,
      width: 120,
      sortable: true,
    },
    {
      cellType: "text",
      header: "State",
      value: (row) => row.funder_state,
      width: 70,
      sortable: true,
    },
    {
      cellType: "text",
      header: "Street Address",
      value: (row) => row.funder_street,
      width: 200,
      sortable: true,
    },
    {
      cellType: "text",
      header: "Tax Year",
      value: (row) => this.props.taxYear,
      width: 110,
      sortable: true,
    },
    {
      cellType: "text",
      header: "Return Type",
      value: (row) => row.org_type,
      width: 120,
      sortable: true,
    },
    {
      cellType: "number",
      header: "Community Nonprofits Funded (#)",
      value: (row) => row.grantsForSelectedYear.length,
      width: 280,
      sortable: true,
    },
    {
      cellType: "number",
      header: "Community Nonprofits Funded ($)",
      value: (row) =>
        row.yearly_grant_totals && row.yearly_grant_totals[this.props.taxYear],
      width: 280,
      sortable: true,
    },
  ];

  render() {
    const nonprofitFundersCount = this.props.data.filter(
      (i) => i.org_type === "990"
    ).length;
    const fundersCount = this.props.data.length - nonprofitFundersCount;
    const subtitle = `${stringifyInteger(
      fundersCount
    )} Foundation Funders and ${stringifyInteger(
      nonprofitFundersCount
    )} Nonprofit Funders`;

    return (
      <TablePane
        title="Community Funders"
        titleInfoContent={
          <React.Fragment>
            {/* prettier-ignore */}
            <p>Displays a national list of Foundation Funders and Nonprofit Funders that have made grants to tax-exempt organizations in the selected geography. Foundation Funders are defined as <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.irs.gov/forms-pubs/about-form-990-pf" target="_blank" rel="noreferrer">private foundations</a> that have made grants to tax-exempt organizations in the selected geography. Nonprofit Funders are defined as <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.irs.gov/charities-non-profits/form-990-series-which-forms-do-exempt-organizations-file-filing-phase-in" target="_blank" rel="noreferrer">tax-exempt organizations</a> that have made grants to other tax-exempt organizations in the selected geography.</p>
          </React.Fragment>
        }
        subtitle={subtitle}
        table={
          <Table
            tableClasses="mt-4"
            columnConfig={this.columnConfig}
            data={this.props.data}
            taxYear={this.props.taxYear}
            onRowClick={this.props.setModalEin}
            rsuiteTableProps={{ fillHeight: true, virtualized: true }}
          />
        }
      />
    );
  }
}

export { NonprofitTablePane, FunderTablePane };
