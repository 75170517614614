import React from "react";
import { point, featureCollection } from "@turf/helpers";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { Modal, enableMainPageScroll } from "./Modal";
import { mapboxAccessToken } from "../config";
import roundedRectangle from "../rounded-rectangle.png";

mapboxgl.accessToken = mapboxAccessToken;

export default class GeographySelectModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.map = null;
    this.mapContainer = React.createRef();
  }

  componentDidMount() {
    // React's `StrictMode` may double-mount components when
    // developing locally, so if the map already exists then
    // skip re-createing it
    // https://www.reddit.com/r/reactjs/comments/tx8zwt/comment/i3k8oga/
    if (this.map) {
      return;
    }

    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: [0, 0],
      zoom: 20,
      interactive: false,
    });

    this.map.once("idle", () => {
      this.map.fitBounds(
        // These are the map bounds for the continental
        // United States
        [
          [-124.6, 49.4],
          [-66.9, 24.8],
        ],
        {
          padding: 40,
          // Do not pan or do any other animation on
          // the zoom/recenter
          animate: false,
        }
      );

      this.map.addSource("buttons", {
        type: "geojson",
        data: featureCollection([
          point([-77.0, 38.9], {
            geography: "dc",
            displayText: "Washington, DC\nWards 7 & 8",
          }),
          point([-90.1, 30.0], {
            geography: "nola",
            displayText: "New Orleans",
          }),
          point([-122.1, 37.5], {
            geography: "epa",
            displayText: "East Palo Alto",
          }),
        ]),
      });

      // Loading and using local PNG/JPEGs is not particularly
      // well documented, so linking to a useful GitHub
      // Issue comment:
      // https://github.com/mapbox/mapbox-gl-js/issues/8037#issuecomment-475586038
      this.map.loadImage(roundedRectangle, (error, image) => {
        if (error) {
          throw error;
        }

        // See documentation for `addImage`'s parameters here:
        // https://docs.mapbox.com/mapbox-gl-js/api/map/#map#addimage
        const imageWidth = 972;
        const imageHeight = 972;
        const contentPadding = 40;
        const cornerRadius = 25;
        this.map.addImage("button", image, {
          content: [
            contentPadding,
            contentPadding,
            imageWidth - contentPadding,
            imageHeight - contentPadding,
          ],
          stretchX: [[cornerRadius, imageWidth - cornerRadius]],
          stretchY: [[cornerRadius, imageHeight - cornerRadius]],
          pixelRatio: 2,
        });

        this.map.addLayer({
          id: "buttons",
          type: "symbol",
          source: "buttons",
          layout: {
            "text-field": ["get", "displayText"],
            "icon-text-fit": "both",
            "icon-image": "button",
            "icon-allow-overlap": true,
            "text-allow-overlap": true,
          },
        });

        this.map.on("click", "buttons", (e) => {
          const geography = e.features[0].properties.geography;

          // This will set the `<App>`'s `state.geography`
          // and thereby will exit out of this modal
          this.props.switchGeography(geography);
          // Given that this component uses the base "Modal" component
          // and by default Modal "disables" scrolling of the main page,
          // we must "re-enable" scroll after switching geography.
          // Passing "true" allows us to scroll the user to the top of
          // the page, thus improving the user experience.
          enableMainPageScroll(true);
        });

        // Switch to a pointer cursor to emphasize to
        // the user that they can click on these city
        // labels
        this.map.on("mouseover", "buttons", () => {
          this.map.getCanvas().style.cursor = "pointer";
        });
        this.map.on("mouseleave", "buttons", () => {
          this.map.getCanvas().style.cursor = "";
        });
      });
    });
  }

  render() {
    const content = (
      <React.Fragment>
        {/* prettier-ignore */}
        <p className="mb-4">This platform displays community-level data on topics related to a community's well-being and the support it receives from nonprofits and philanthropy. Data are sourced from public <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.irs.gov/charities-non-profits/tax-exempt-organization-search-bulk-data-downloads" target="_blank" rel="noreferrer">Form 990</a> filings, most recently available data from the <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.irs.gov/charities-non-profits/exempt-organizations-business-master-file-extract-eo-bmf" target="_blank" rel="noreferrer">Internal Revenue Service (IRS)</a> and <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://nccs-data.urban.org/data.php?ds=bmf" target="_blank" rel="noreferrer">National Center for Charitable Statistics (NCCS)</a> business master files, and proprietary data on program services shared by nonprofits.</p>
        {/* prettier-ignore */}
        <p className="mb-4">* For this platform, data are available starting in 2018 with <i>limited data for 2021</i> as the IRS is substantially delayed in processing and releasing nonprofit filings.</p>
        <p className="mb-4">
          To learn more about this project email{" "}
          <a
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            href="mailto:hello@communitypartnershipfinder.org"
          >
            hello@communitypartnershipfinder.org
          </a>
          .
        </p>
        <p className="font-bold">
          To begin your exploration, select a geographic area on the map below.
        </p>

        <h2 className="text-2xl mt-8 mb-3">Select Geography</h2>
        <div
          ref={this.mapContainer}
          style={{
            height: "25rem",
            width: "100%",
            border: "0.0625rem solid black",
          }}
        ></div>
      </React.Fragment>
    );

    return (
      <Modal
        title={
          <span>
            Community Partnership Finder<sup>BETA</sup>
          </span>
        }
        content={content}
      />
    );
  }
}
