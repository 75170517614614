import React from "react";
import Select from "react-select";
import { range, startCase } from "lodash";
import { InfoIcon } from "./Info";

export default function Filters({
  geography,
  taxYear,
  issueAreas,
  unsetGeography,
  setTaxYear,
  setIssueAreas,
}) {
  const geographyLabels = {
    dc: "Washington, DC Wards 7 & 8",
    epa: "East Palo Alto",
    nola: "New Orleans",
  };

  const issueAreaOptions = ["education", "immigration"];

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      // This is necessary to get the geography `<button>`
      // to align vertically with the geography text
      className="flex items-center"
    >
      <label className="text-lg">
        <span className="font-bold">Geography</span>:{"\u00A0"}
        <span>{geographyLabels[geography]}</span>
      </label>
      <button
        onClick={() => {
          unsetGeography();
        }}
        className="ml-2 mr-12 inline-block px-3 py-1.5 text-xs rounded border border-black leading-none"
      >
        Change
      </button>

      <label className="text-lg mr-12">
        <span className="font-bold">Tax Year</span>:{"\u00A0"}
        <select
          className="form-select appearance-none w-30 border border-black rounded py-1.5"
          value={taxYear}
          onChange={(e) => {
            setTaxYear(parseInt(e.target.value));
          }}
        >
          {range(2018, 2022).map((year) => (
            <option value={year} key={year}>
              {year}
            </option>
          ))}
        </select>{" "}
        <InfoIcon
          elementName="Tax Year Filter"
          content={
            <React.Fragment>
              {/* prettier-ignore */}
              <p className="mb-4">Displays tax-exempt organizations who filed a Form 990, informational tax form that most tax-exempt organizations must file annually with the IRS for the year selected. Form 990 is due on the 15th day of the 5th month following the end of the organization's taxable year. For organizations on a calendar year, the Form 990 is due on May 15th of the following year.</p>
              {/* prettier-ignore */}
              <p className="mb-4">Displayed data comes from public <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.irs.gov/charities-non-profits/tax-exempt-organization-search-bulk-data-downloads" target="_blank" rel="noreferrer">Form 990</a> filings and the most recently available data from business master files sourced from the <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.irs.gov/charities-non-profits/exempt-organizations-business-master-file-extract-eo-bmf" target="_blank" rel="noreferrer">Internal Revenue Service (IRS)</a> and <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://nccs-data.urban.org/data.php?ds=bmf" target="_blank" rel="noreferrer">National Center for Charitable Statistics (NCCS)</a>. These files are cumulative and contain selected information from Form 990 filings that have been submitted to the IRS. Where possible both business master files are used to build the most complete record possible for an organization.</p>
              {/* prettier-ignore */}
              <p>* For this platform, data are available starting in 2018 with <em>limited data for 2021</em> as the IRS is substantially delayed in processing and releasing nonprofit filings.</p>
            </React.Fragment>
          }
        />
      </label>

      <label className="text-lg">
        <span className="font-bold">Topics</span>:{"\u00A0"}
        {/*
        This multi-select is not a controlled component currently,
        but that's alright for now. Might be worth refactoring in
        the future. Docs are verbose but oddly scant on examples:
        https://react-select.com/props
        */}
        <Select
          isMulti
          options={issueAreaOptions.map((i) => ({
            label: startCase(i),
            value: i,
          }))}
          closeMenuOnSelect
          isSearchable={false}
          placeholder="Filters each topic separately"
          onChange={(e) => {
            setIssueAreas(e.map((i) => i.value));
          }}
          // This is the only way to apply styling overrides
          // in this component library
          // prettier-ignore
          styles={{
            container: (provided) => ({...provided, display: "inline"}),
            control: (provided) => ({...provided, width: "20rem", display: "inline-flex", border: "0.0625rem solid black"}),
            placeholder: (provided) => ({...provided, fontSize: "1rem"}),
            option: (provided) => ({...provided, fontSize: "1rem"}),
            dropdownIndicator: (provided) => ({...provided, color: "rgb(130,130,130)"}),
            clearIndicator: (provided) => ({...provided, color: "rgb(130,130,130)"}),
            indicatorSeparator: (provided) => ({...provided, display: "none"}),
            multiValueLabel: (provided) => ({...provided, padding: 0}),
          }}
        />{" "}
        <InfoIcon
          elementName="Topics Filter"
          content={
            <React.Fragment>
              <p className="mb-4">
                Topics are designed to display a filtered list of tax-exempt
                organizations with phrases and terms associated with the keyword
                based on classifications of mission statements in Form 990s. In
                the case of Community Funders, this filtered list includes
                Foundation and Nonprofit Funders who have provided grants to
                organizations that match the selected topic(s). Please note that
                the Topics filter does not affect the data displayed when
                viewing information on a <i>specific</i> Community Funder, you
                will instead be shown unfiltered data under the Community
                Funding Details section.
              </p>
              <span>Current topic glossary:</span>
              <ul className="list-disc pl-10 my-2">
                <li>
                  Immigration: Movement of people into a destination country of
                  which they are not natives or do not possess citizenship.
                  Includes keywords such as:
                  <ul className="list-disc list-outside ml-10">
                    <li>
                      {[
                        "tps",
                        "asylum",
                        "path to citizenship",
                        "u.s. citizenship",
                        "citizenship application",
                        "united states citizenship",
                        "family visas",
                        "visas",
                        "daca",
                        "domestic worker",
                        "dreamer",
                        "farm worker",
                        "green card",
                        "immigran",
                        "immigrat",
                        "migrant",
                        "migration",
                        "refugee",
                        "separating families",
                        "temporary protected status",
                        "tps holder",
                        "unaccompanied minor",
                        "undocumented",
                        "deportation",
                      ]
                        .map((i) => <code key={i}>{i}</code>)
                        .reduce((accumulator, current) => [
                          accumulator,
                          ", ",
                          current,
                        ])}
                    </li>
                  </ul>
                </li>
                <li>
                  Education: K-12 education. Includes keywords such as:
                  <ul className="list-disc list-outside ml-10">
                    <li>
                      {[
                        "after school",
                        "alternative school",
                        "charter school",
                        "classroom",
                        "college prep",
                        "college readiness",
                        "competency based",
                        "early childhood",
                        "education reform",
                        "educational change",
                        "educational equity",
                        "educational inequality",
                        "elementary school",
                        "high school",
                        "juvenile justice",
                        "kindergarten",
                        "middle school",
                        "public education",
                        "public school",
                        "school board",
                        "school children",
                        "school district",
                        "school system",
                        "stem",
                      ]
                        .map((i) => <code key={i}>{i}</code>)
                        .reduce((accumulator, current) => [
                          accumulator,
                          ", ",
                          current,
                        ])}
                    </li>
                  </ul>
                </li>
              </ul>
            </React.Fragment>
          }
        />
      </label>
    </form>
  );
}
