import React from "react";
import * as ReactDOM from "react-dom/client";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { featureCollection, point } from "@turf/helpers";
import { debounce, startCase, uniq } from "lodash";
import { InfoIcon } from "./Info";
import { stringifyInteger } from "../utils";
import { mapboxAccessToken } from "../config";

const doElementsIntersect = (elementA, elementB) => {
  if (!elementA || !elementB) {
    return false;
  }

  const rectA = elementA.getBoundingClientRect();
  const rectB = elementB.getBoundingClientRect();

  // prettier-ignore
  const intersectOnXAxis =
    // A is within B
    (rectA.left > rectB.left && rectA.left < rectB.right) ||
    (rectA.right > rectB.left && rectA.right < rectB.right) ||
    // B is within A
    (rectB.left > rectA.left && rectB.left < rectA.right) ||
    (rectB.right > rectA.left && rectB.right < rectA.right)
  // prettier-ignore
  const intersectOnYAxis =
    // A is within B
    (rectA.top > rectB.top && rectA.top < rectB.bottom) ||
    (rectA.bottom > rectB.top && rectA.bottom < rectB.bottom) ||
    // B is within A
    (rectB.top > rectA.top && rectB.top < rectA.bottom) ||
    (rectB.bottom > rectA.top && rectB.bottom < rectA.bottom)

  return intersectOnXAxis && intersectOnYAxis;
};

const Popup = ({ name, address, issueAreasText, taxYear, revenue }) => (
  <div className="font-body text-sm leading-tight">
    <h3 className="font-semibold">{name}</h3>
    <p className="mt-2.5 text-xs">{address}</p>
    {issueAreasText && (
      <p className="mt-2.5 text-xs">
        <b>Topics:</b> {issueAreasText}
      </p>
    )}
    <p className="mt-2.5 text-xs">
      <b>{taxYear} Revenue:</b> {stringifyInteger(revenue, "$")}
    </p>
  </div>
);

const Legend = ({ indexLabel, visible }) => {
  return (
    <div
      id="legend"
      className={`relative bg-white border border-black p-3 w-[13rem] h-[16rem] top-[-18.2rem] left-[1rem] ${
        !visible && "invisible"
      }`}
    >
      <div>
        <h3 className="font-semibold leading-tight text-sm mb-1">
          {indexLabel}
        </h3>
        <div className="flex justify-between">
          <div className="h-4 w-[2.625rem] bg-index-background-purple-1 border border-index-border-purple"></div>
          <div className="h-4 w-[2.625rem] bg-index-background-purple-2 border border-index-border-purple"></div>
          <div className="h-4 w-[2.625rem] bg-index-background-purple-3 border border-index-border-purple"></div>
          <div className="h-4 w-[2.625rem] bg-index-background-purple-4 border border-index-border-purple"></div>
        </div>
        <div className="flex justify-between">
          <span className="text-sm">Low</span>
          <span className="text-sm">High</span>
        </div>
      </div>

      <div className="mt-4">
        <h3 className="font-semibold leading-tight text-sm mb-1">
          Organization Type{" "}
          <InfoIcon
            elementName="Organization Types"
            content={
              <React.Fragment>
                {/* prettier-ignore */}
                <p>To understand the types of tax-exempt organizations in a selected geography, the data has been categorized into:</p>
                {/* prettier-ignore */}
                <ul className="list-disc pl-10 my-2">
                  <li>Nonprofits: <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.irs.gov/charities-non-profits/form-990-series-which-forms-do-exempt-organizations-file-filing-phase-in" target="_blank" rel="noreferrer">Tax-exempt organizations</a> in the selected geography that file Form 990 and 990EZ where funds are used to pursue the organization’s mission.</li>
                  <li>Nonprofit funders: <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.irs.gov/charities-non-profits/form-990-series-which-forms-do-exempt-organizations-file-filing-phase-in" target="_blank" rel="noreferrer">Tax-exempt organizations</a> in the selected geography that file Form 990 and have made grants to other tax-exempt organizations in the selected geography.</li>
                  <li>Foundation funders: <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.irs.gov/forms-pubs/about-form-990-pf" target="_blank" rel="noreferrer">Private foundations</a> in the selected geography that file Form 990PF and have made grants to other tax-exempt organizations in the selected geography.</li>
                </ul>
              </React.Fragment>
            }
          />
        </h3>
        <div className="flex items-center">
          <div className="h-2 w-2 rounded-full bg-nonprofit-green inline-block"></div>
          <span className="ml-1 text-sm">Nonprofit</span>
        </div>
        <div className="flex items-center">
          <div className="h-2 w-2 rounded-full bg-nonprofit-funder-orange inline-block"></div>
          <span className="ml-1 text-sm">Nonprofit Funder</span>
        </div>
        <div className="flex items-center">
          <div className="h-2 w-2 rounded-full bg-funder-blue inline-block"></div>
          <span className="ml-1 text-sm">Foundation Funder</span>
        </div>
      </div>

      <div className="mt-4">
        <h3 className="font-semibold leading-tight text-sm mb-1">
          Organization Revenue{" "}
          <InfoIcon
            elementName="Organization Revenue"
            content="Metric refers to the amount of funds generated at the tax-exempt organization for the tax filing year selected."
          />
        </h3>
        <div className="flex justify-between items-center px-4">
          <div className="flex items-center">
            <div className="h-1.5 w-1.5 rounded-full bg-slate-300"></div>
            <span className="ml-1 text-sm">$10k</span>
          </div>
          <div className="flex items-center">
            <div className="h-6 w-6 rounded-full bg-slate-300"></div>
            <span className="ml-1 text-sm">$10M</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const IndexSelector = ({ selectedOption, options, onChange, visible }) => (
  <div
    id="index-selector"
    className={`relative bg-white border border-black w-[21rem] p-2 top-[-43rem] left-[1rem] ${
      !visible && "invisible"
    }`}
  >
    <h3 className="font-semibold text-sm mb-1">
      Index Layer{" "}
      <InfoIcon
        elementName="Index Layers"
        content={
          <React.Fragment>
            <p>
              Indexes can bring indicators onto a map to see patterns and to
              visually analyze data, which allows for a better understanding of
              where communities could benefit from dedicated resources. A
              description of the Community Need Index and its sub-indexes can be
              found below:
            </p>
            <h3 className="text-lg mt-6">Community Need Index</h3>
            <p>
              Measures 7 key indicators (where available) to calculate the
              well-being of a geographic area. This is a multidimensional
              picture of a community that surfaces on dimensions of democracy,
              diversity, education, environment, health, and public safety.
            </p>
            <h3 className="text-lg mt-6">Non-Voting Sub-Index</h3>
            <p>
              Provides the percentage of registered voters that did not vote at
              the census tract level in the 2020 general election. See
              Redistricting Data Hub's{" "}
              <a
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://redistrictingdatahub.org/data/about-our-data/voter-file/"
                target="_blank"
                rel="noreferrer"
              >
                description
              </a>{" "}
              on voter file data, this sub-index specifically uses the “2020 L2
              Voterfile Elections Turnout Statistics Aggregated to Census
              Blocks” files aggregated to census tracts.
            </p>
            <h3 className="text-lg mt-6">Environmental Burden Sub-Index</h3>
            <p>
              Provides a measurement to understand the impacts of those most at
              risk of the negative impacts of environmental pollution on human
              health. The data includes measures of air pollution, potential
              hazardous or toxic waste, transportation infrastructure, and
              nearby parks and greenspace. See CDC's{" "}
              <a
                className="underline
              text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://www.atsdr.cdc.gov/placeandhealth/eji/indicators.html"
                target="_blank"
                rel="noreferrer"
              >
                documentation
              </a>{" "}
              of its environmental burden index.
            </p>
            <h3 className="text-lg mt-6">Health Vulnerability Sub-Index</h3>
            <p>
              Provides a measurement to understand the prevalence of chronic
              diseases and mental health issues that are believed to have the
              potential to be tied to, or exacerbated by local environmental
              quality. See CDC's{" "}
              <a
                className="underline text-blue-600
              hover:text-blue-800 visited:text-purple-600"
                href="https://www.atsdr.cdc.gov/placeandhealth/eji/indicators.html"
                target="_blank"
                rel="noreferrer"
              >
                documentation
              </a>{" "}
              of its environmental burden index.
            </p>
            <h3 className="text-lg mt-6">Gun Violence Sub-Index</h3>
            <p>
              Provides an averaged measure for incidents of gun violence and
              shootings. Using shooting incident address level data the index is
              the interpolated average number shootings over census tract areas.
              See Gun Violence Archive’s{" "}
              <a
                className="underline
              text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://www.gunviolencearchive.org/methodology"
                target="_blank"
                rel="noreferrer"
              >
                documentation
              </a>{" "}
              of its methodology for generating the source data.
            </p>
            <h3 className="text-lg mt-6">Minority Vulnerability Sub-Index</h3>
            <p>
              Provides a measure to understand the percentage of the reported
              population for a specific race and ethnicity category and the
              percentage of the reported population of persons over age 5 who
              speak English “Less than Well”. See CDC's{" "}
              <a
                className="underline
              text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://www.atsdr.cdc.gov/placeandhealth/svi/documentation/SVI_documentation_2018.html"
                target="_blank"
                rel="noreferrer"
              >
                documentation
              </a>{" "}
              of its minority status and language.
            </p>
            <h3 className="text-lg mt-6">Socioeconomic Need Sub-Index (SVI)</h3>
            <p>
              Provides a measure to understand the percentage of the reported
              population that are part of specific demographic groups based on
              poverty, unemployment, income and education. See CDC's{" "}
              <a
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://www.atsdr.cdc.gov/placeandhealth/svi/documentation/SVI_documentation_2018.html"
                target="_blank"
                rel="noreferrer"
              >
                documentation
              </a>{" "}
              of its minority status and language.
            </p>
            <h3 className="text-lg mt-6">Subsidized School Lunch Sub-Index</h3>
            <p>
              Provides a measurement to the number of students eligible to
              participate in the Free Lunch Program under the National School
              Lunch Act of 1946. Using school level data the index is the
              interpolated average number of free and reduced lunch program
              students over census tract areas. See the National Center for
              Education Statistics Elementary/Secondary Information System's
              (ElSi){" "}
              <a
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://nces.ed.gov/training/datauser/CCD_04.html"
                target="_blank"
                rel="noreferrer"
              >
                documentation
              </a>{" "}
              for generating the source data. This data is not available for our
              Washington, DC (Ward 7 & 8) geographic sub area.
            </p>
          </React.Fragment>
        }
      />
    </h3>
    <select
      value={selectedOption}
      onChange={onChange}
      className="form-select form-select-sm appearance-none w-full border border-black rounded text-sm py-1"
    >
      {Object.entries(options).map(([name, label]) => (
        <option value={name} key={name}>
          {label}
        </option>
      ))}
    </select>
  </div>
);

mapboxgl.accessToken = mapboxAccessToken;

export default class MapPane extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      indexName: "classed_index_value",
      showLegend: true,
      showIndexSelector: true,
    };

    this.geographyMapConfigurations = {
      dc: {
        zoom: 10,
        minZoom: 10,
        maxZoom: 18,
        center: [-76.97, 38.87],
      },
      epa: {
        zoom: 10,
        minZoom: 10,
        maxZoom: 20,
        center: [-122.15, 37.45],
      },
      nola: {
        zoom: 9,
        minZoom: 9,
        maxZoom: 20,
        center: [-90.1, 30.0],
      },
    };

    // This pointer will be used to refer to `map` outside of the
    // `componentDidMount` method in which it was created
    this.map = null;

    // creating a JS pointer towards a div that hosts the map
    // ref is how you use JS to address a component in the page
    this.mapContainer = React.createRef();

    this.moveMap = this.moveMap.bind(this);
    this.loadIndexGeoJSON = this.loadIndexGeoJSON.bind(this);
    this.updateMapPoints = this.updateMapPoints.bind(this);
    this.switchIndex = this.switchIndex.bind(this);

    // Use debouncing to avoid dealing with multiple, synchronous
    // method calls triggered during the page-load process
    const debounceMilliseconds = 1000;
    this.debouncedMoveMap = debounce(() => {
      this.moveMap();
    }, debounceMilliseconds);
    this.debouncedLoadIndexGeoJSON = debounce(() => {
      this.loadIndexGeoJSON();
    }, debounceMilliseconds);
    this.debouncedUpdateMapPoints = debounce(() => {
      this.updateMapPoints();
    }, debounceMilliseconds);
  }

  componentDidUpdate(prevProps) {
    if (
      // We'll get an error if we try interacting with the map object
      // before it's fully loaded in the browser
      this.map &&
      this.map.loaded() &&
      // Don't update the map if the user is in the middle
      // of switching the geography
      this.props.geography !== null
    ) {
      // If the user has switched geographies, zoom and pan
      // to the new one
      if (this.props.geography !== prevProps.geography) {
        this.debouncedMoveMap();
        this.debouncedLoadIndexGeoJSON();
      }

      this.debouncedUpdateMapPoints();
    }
  }

  // Update map configuration and zoom to the new geography
  moveMap() {
    const config = this.geographyMapConfigurations[this.props.geography];

    this.map.setMinZoom(config.minZoom);
    this.map.setMaxZoom(config.maxZoom);
    this.map.jumpTo({
      zoom: config.zoom,
      center: config.center,
    });
  }

  loadIndexGeoJSON() {
    this.map.getSource("index").setData(this.props.indexData);
  }

  switchIndex(indexName) {
    this.setState({ indexName });

    this.map.setFilter("index", ["!=", ["get", indexName], null]);
    this.map.setFilter("indexBorder", ["!=", ["get", indexName], null]);

    this.map.setPaintProperty(
      "index",
      "fill-color",
      this.generateFillColorExpression(indexName)
    );
  }

  generateFillColorExpression(indexName) {
    // prettier-ignore
    return [
      "step",
      ["get", indexName],
      // Default color, used for `1`s
      "rgba(249, 240, 255, 0.3)",
      // Color steps
      2, "rgba(226, 178, 255, 0.3)",
      3, "rgba(192, 90, 255, 0.3)",
      4, "rgba(158, 0, 255, 0.3)",
    ]
  }

  // Add sources and layers _once_, so that they can just be
  // updated in the future
  initializeSourcesAndLayers() {
    const sources = [
      "nonprofits",
      "nonprofitFunders",
      "funders",
      "allData",
      "index",
    ];
    sources.forEach((source) => {
      this.map.addSource(source, {
        type: "geojson",
        data: featureCollection([]),
      });
    });

    const defaultPointPaint = {
      // prettier-ignore
      "circle-radius": [
        "step",
        // Use `to-number` to convert `null`s to `0`s
        ["to-number", ["get", "revenue"]],
        // Default size
        3,
        // Size steps
        100000, 6,
        1000000, 9,
        10000000, 12,
      ],
      "circle-stroke-width": 1,
      // Make the stroke slightly transparent so that it's
      // less jarring against the index's background color
      "circle-stroke-color": "rgba(256, 256, 256, 0.85)",
    };
    const defaultPointLayout = {
      // Render the smallest circles on top
      "circle-sort-key": ["*", ["to-number", ["get", "revenue"]], -1],
    };

    // The order of these `addLayer`s matters, because the
    // last to be added will be visually on top on the map
    this.map.addLayer({
      id: "index",
      type: "fill",
      source: "index",
      filter: ["!=", ["get", this.state.indexName], null],
      paint: {
        "fill-color": this.generateFillColorExpression(this.state.indexName),
      },
    });

    // Need to use a separate layer to apply a border/stroke
    // to a polygon layer
    // https://github.com/mapbox/mapbox-gl-js/issues/4088#issuecomment-285801635
    this.map.addLayer({
      id: "indexBorder",
      type: "line",
      source: "index",
      filter: ["!=", ["get", this.state.indexName], null],
      paint: {
        "line-color": "rgba(206, 125, 255, 0.7)",
        "line-width": 1,
      },
    });

    this.map.addLayer({
      id: "nonprofits",
      type: "circle",
      source: "nonprofits",
      paint: Object.assign({}, defaultPointPaint, {
        "circle-color": "#7FC97F",
      }),
      layout: defaultPointLayout,
    });

    this.map.addLayer({
      id: "nonprofitFunders",
      type: "circle",
      source: "nonprofitFunders",
      paint: Object.assign({}, defaultPointPaint, {
        "circle-color": "#FDC086",
      }),
      layout: defaultPointLayout,
    });

    this.map.addLayer({
      id: "funders",
      type: "circle",
      source: "funders",
      paint: Object.assign({}, defaultPointPaint, {
        "circle-color": "#386CB0",
      }),
      layout: defaultPointLayout,
    });

    this.map.addLayer({
      id: "allData",
      type: "circle",
      source: "allData",
      paint: Object.assign({}, defaultPointPaint, {
        "circle-opacity": 0,
        "circle-color": "#000",
        "circle-stroke-width": 0,
        "circle-stroke-color": "#000",
      }),
      layout: defaultPointLayout,
    });

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });

    this.map.on("click", "allData", (e) => {
      const feature = e.features[0];
      const ein = feature.properties.ein;
      this.props.setModalEin(ein);
    });

    // make popup appear when mouse enters
    this.map.on("mouseenter", "allData", (e) => {
      // change the curse style as a UI indicator
      this.map.getCanvas().style.cursor = "pointer";
      // parse in information into popup component
      const feature = e.features[0];
      const popupNode = document.createElement("div");
      const root = ReactDOM.createRoot(popupNode);
      root.render(<Popup {...feature.properties} />);
      const coordinates = feature.geometry.coordinates.slice();
      // populate the popup and set its coordinates based on
      // the feature found
      popup.setLngLat(coordinates).setDOMContent(popupNode).addTo(this.map);

      // Hide map elements if they'd block the tooltip. Since
      // these elements are not direct descendents or
      // siblings, `z-index` will not solve the overlapping.
      // Need a _slight_ delay to wait until the popup node
      // fully renders on the page; otherwise the popup's
      // bounding rectangle is inaccurate (eg, suggests that
      // the element is only ~20 pixels wide).
      setTimeout(() => {
        const popupElement = window.document.querySelector(".mapboxgl-popup");
        const legendElement = window.document.querySelector("#legend");
        const indexSelectorElement =
          window.document.querySelector("#index-selector");

        if (doElementsIntersect(popupElement, legendElement)) {
          this.setState({ showLegend: false });
        }
        if (doElementsIntersect(popupElement, indexSelectorElement)) {
          this.setState({ showIndexSelector: false });
        }
      }, 20);
    });

    // make popup disappear when mouse leaves
    this.map.on("mouseleave", "allData", () => {
      this.map.getCanvas().style.cursor = "";
      popup.remove();
      this.setState({
        showLegend: true,
        showIndexSelector: true,
      });
    });
  }

  updateMapPoints() {
    const nonprofitEins = this.props.nonprofitData.map((i) => i.ein);
    const funderEins = this.props.funderData.map((i) => i.ein);
    const funder990Eins = this.props.funderData
      .filter((i) => i.org_type === "990")
      .map((i) => i.ein);
    const nonprofitFunderEins = uniq(
      nonprofitEins
        .filter((i) => funderEins.includes(i))
        // Even if an organization appears active in `funders.json` but
        // _not_ in `nonprofits.json` for a year, it should _always_ be
        // labeled as a nonprofit funder instead of just a funder
        .concat(funder990Eins)
    );

    const nonprofitFeatures = this.props.nonprofitData
      .filter((i) => !nonprofitFunderEins.includes(i.ein))
      .map((i) =>
        point([i.lon_jitter, i.lat_jitter], {
          ein: i.ein,
          name: i.name,
          address: i.street,
          // Seems like nested properties are not allowed in
          // GeoJSON Feature properties
          issueAreasText:
            i.org_classes && i.org_classes.map(startCase).join(", "),
          ntee: i.ntee,
          description: i.description3,
          taxYear: this.props.taxYear,
          revenue: i.revenue_timeline[this.props.taxYear],
        })
      );

    const funderFeatures = this.props.funderData
      .filter((i) => !nonprofitFunderEins.includes(i.ein))
      // Must ignore non-local funders
      .filter((i) => i.lon_jitter !== null)
      .map((i) =>
        point([i.lon_jitter, i.lat_jitter], {
          ein: i.ein,
          name: i.funder_name,
          address: i.funder_street,
          // We could potentially change this in the future to
          // indicate the issue areas _funded_ by a funder,
          // based on the `org_classes` value of the nonprofits
          // they funded in that year
          issueAreasText: "",
          ntee: i.funder_ntee,
          taxYear: this.props.taxYear,
          // If a funder has no listed revenue for a year,
          // then for dot sizing and tooltip presentation
          // we should set it to zero
          revenue: i.funder_revenue_timeline[this.props.taxYear] || 0,
        })
      );

    const nonprofitFunderFeatures = this.props.funderData
      .filter((i) => nonprofitFunderEins.includes(i.ein))
      .filter((i) => i.lon_jitter !== null)
      .map((i) =>
        point([i.lon_jitter, i.lat_jitter], {
          ein: i.ein,
          name: i.funder_name,
          address: i.funder_street,
          issueAreasText: i.org_classes && i.org_classes.join(", "),
          ntee: i.funder_ntee,
          taxYear: this.props.taxYear,
          revenue: i.funder_revenue_timeline[this.props.taxYear] || 0,
        })
      );
    // Add more fields onto the nonprofit funders, since they are in
    // both of the data arrays
    nonprofitFunderFeatures.forEach((i) => {
      const matchingNonprofitEntity = this.props.nonprofitData.find(
        (j) => j.ein === i.ein
      );
      if (matchingNonprofitEntity) {
        i.issueAreasText =
          matchingNonprofitEntity.org_classes &&
          matchingNonprofitEntity.org_classes.join(", ");
        i.properties.ntee = matchingNonprofitEntity.ntee;
        i.properties.description = matchingNonprofitEntity.description3;
        i.properties.revenue =
          matchingNonprofitEntity.revenue_timeline[this.props.taxYear];
      }
    });

    this.map
      .getSource("nonprofits")
      .setData(featureCollection(nonprofitFeatures));
    this.map.getSource("funders").setData(featureCollection(funderFeatures));
    this.map
      .getSource("nonprofitFunders")
      .setData(featureCollection(nonprofitFunderFeatures));

    const allFeatures = nonprofitFeatures
      .concat(funderFeatures)
      .concat(nonprofitFunderFeatures);
    this.map.getSource("allData").setData(featureCollection(allFeatures));
  }

  componentDidMount() {
    // React's `StrictMode` may double-mount components when
    // developing locally, so if the map already exists then
    // skip re-createing it
    // https://www.reddit.com/r/reactjs/comments/tx8zwt/comment/i3k8oga/
    if (this.map) {
      return;
    }

    this.map = new mapboxgl.Map({
      container: this.mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      // This will put the user in the middle of the Gulf
      // of Guinea, away from any land; the map will just
      // appear dark gray and unactionable
      center: [0, 0],
      zoom: 20,
    });

    this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }));

    // The `idle` event is _slightly_ preferred to `load`, but
    // unfortunately neither are perfect indicators of whether
    // the map is ready for layers to be added
    // https://github.com/mapbox/mapbox-gl-js/issues/2268
    this.map.once("idle", () => {
      this.initializeSourcesAndLayers();

      // Don't trigger the geography switch if the geography
      // hasn't been chosen yet
      if (this.props.geography) {
        this.debouncedUpdateMapPoints();
        this.debouncedMoveMap();
        this.debouncedLoadIndexGeoJSON();
      }
    });
  }

  render() {
    const indexLabels = {
      // Maintain index/subindex order as it's listed in
      // the MVP Copy document
      classed_index_value: "Community Need Index",
      classed_democracy: "Non-Voting Sub-Index",
      classed_environmental_burdern: "Environmental Burden Sub-Index",
      classed_health_vulnerability: "Health Vulnerability Sub-Index",
      classed_gun_violence: "Gun Violence Sub-Index",
      classed_minority_status_lang: "Minority Vulnerability Sub-Index",
      classed_socioeconomic_status: "Socioeconomic Need Sub-Index (SVI)",
      classed_free_and_reduced_lunch: "Subsidized School Lunch Sub-Index",
    };
    const indexLabel = indexLabels[this.state.indexName];

    return (
      <section className="w-full h-[28rem]">
        <div
          ref={this.mapContainer}
          className="border border-black h-full"
        ></div>
        <Legend indexLabel={indexLabel} visible={this.state.showLegend} />
        <IndexSelector
          selectedOption={this.state.indexName}
          options={indexLabels}
          onChange={(e) => {
            this.switchIndex(e.target.value);
          }}
          visible={this.state.showIndexSelector}
        />
      </section>
    );
  }
}
