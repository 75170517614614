import React from "react";

// This responsive modal has 3 main parts: a title, content, and a close button
function Modal(props) {
  disableMainPageScroll();
  return (
    <div
      className="z-2 h-screen w-screen fixed bg-[rgba(0,0,0,0.4)] inset-0"
      // Close out of the modal, but only if the gray
      // background layer was clicked rather than the
      // modal itself
      onClick={(e) => {
        if (props.onClose && e.target === e.currentTarget) {
          props.onClose();
          enableMainPageScroll();
        }
      }}
    >
      <div className="fixed left-1/2 -translate-x-1/2 top-20 h-fit max-h-[min(80vh,80rem)] w-5/6 max-w-[80rem] bg-white border border-black rounded-md px-20 py-10 overflow-y-scroll">
        {/* <!-- This button is for closing the modal --> */}
        {props.onClose && (
          <div className="flex justify-end">
            <button
              onClick={() => {
                props.onClose();
                enableMainPageScroll();
              }}
              className="px-2 py-2 cursor-pointer border border-black rounded"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 19.5l15-15m-15 0l15 15"
                />
              </svg>
            </button>
          </div>
        )}

        {/* Modal Title */}
        <div>
          <h1 className="text-3xl bold mb-4">{props.title}</h1>
        </div>
        {/* Modal Content */}
        <div className="overflow-y-auto overscroll-auto">{props.content}</div>
      </div>
    </div>
  );
}

// Functions enableMainPageScroll(scrollToTop) and disableMainPageScroll() are
// a workaround/hack to implement the scroll-blocking functionality of the main
// page whenever a modal instance is open.
function enableMainPageScroll(scrollToTop) {
  document.body.style.overflow = "";
  // Improve the user experience by scrolling the page to the top:
  if (scrollToTop) window.scrollTo(0, 0, "smooth");
}

function disableMainPageScroll() {
  document.body.style.overflow = "hidden";
}

export { Modal, disableMainPageScroll, enableMainPageScroll };
