import React from "react";
import InfoOutlineIcon from "@rsuite/icons/InfoOutline";
import { Modal } from "./Modal";

// Using this React `Context` permits us to manage the
// info modal component from anywhere. React Contexts
// are good for matters like this that affect the application
// globally.
// https://reactjs.org/docs/context.html
const InfoModalContext = React.createContext();

// Sources/inspirations when building this `Provider`:
// https://devtrium.com/posts/how-use-react-context-pro
// https://stackoverflow.com/a/57840598
const InfoModalProvider = ({ children }) => {
  const [infoElementName, setInfoElementName] = React.useState(null);
  const [infoContent, setInfoContent] = React.useState(null);

  // Use memoization to improve app performance, since
  // all components will be re-rendered when `infoContent`
  // changes since its Provider is at the root of the
  // React tree
  const providerValue = React.useMemo(
    () => ({
      infoElementName,
      setInfoElementName,
      infoContent,
      setInfoContent,
    }),
    [infoElementName, infoContent]
  );

  return (
    <InfoModalContext.Provider value={providerValue}>
      {children}
    </InfoModalContext.Provider>
  );
};

const InfoIcon = ({ elementName, content }) => {
  const context = React.useContext(InfoModalContext);

  return (
    <InfoOutlineIcon
      onClick={() => {
        context.setInfoElementName(elementName);
        context.setInfoContent(content);
      }}
      className="-translate-y-0.5 scale-75 hover:cursor-pointer"
    />
  );
};

const InfoModal = ({ elementName, content }) => {
  const context = React.useContext(InfoModalContext);

  return (
    <Modal
      title={`More information about ${elementName}`}
      content={content}
      onClose={() => context.setInfoContent(null)}
    />
  );
};

export { InfoModalContext, InfoModalProvider, InfoIcon, InfoModal };
